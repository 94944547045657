import React, { Component } from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {Link} from 'react-router-dom';

import {Focusable, Spacer} from './Utils';
import {ldb, log, api, go, edate, edate3, map_list, go_url,
		show_is_new, get_room, get_staff, get_my_feedback} from './Lib';
import {editorToolbar} from './EditTask';

//------------------ Feedback  ------------------------

class FeedbackList extends Component {
	constructor(props) {
		super(props);
		this.state = {working: true};
		
		window.g_myFeedback = this;
	}

	componentDidMount() {
		this.fetch();
	}

	componentDidUpdate() {
	}
	
	fetched = (error, db, resp) => {
		this.setState({working: false});
	}

	fetch = () => { 
		const args = {
			cmd: 'my_feedback'
		};
		api( args, this.fetched );
	}
	
	show_status = (item) => {
		if (item.dt_closed) {
			return 'Closed';
		} else {
			return 'Active';
		}
	}

	progress_spinner = () => {
		return (
		<div>
			<div>
				Fetching Your Feedback Items
			</div>
			<Spacer />
			<ProgressSpinner />
		</div>
		)
	}
	
	feedback_list = () => {
		let items = ldb.data.my_feedback;
		items._order.sort((a, b) => a.id > b.id);
		
		return (
		<div className="feedback-list">
		    <div className="p-grid first-row">
			<div className="p-col-1">
				ID#
			</div>
			<div className="p-col-2">
				Subject
			</div>
			<div className="p-col-2">
				Date Added
			</div>
			<div className="p-col-1">
				Status
			</div>
			<div className="p-col-2">
				
			</div>

			</div>


		{items._order.map(id => {
		    const item = items._items[id];
		    return (
	     	<div className="p-grid item-row" key={id} >
			<div className="p-col-1">
			    {item.id}
			</div>
			<div className="p-col-2">
			    {item.subject}
			</div>
			<div className="p-col-2">
			    {edate(item.dt_added)}
			</div>
			<div className="p-col-1">
			    {this.show_status(item)}
			</div>
			<div className="p-col-2">
			    <Link to={go_url('feedback', 'view', item.id)} title="View/Update Feedback">
			    	View/Update
			    </Link>
			</div>

	     	</div>
		     )
			}, this)}
		</div>
		)
	}

	render() {
		return (
<div className="full-height">
	{this.state.working && this.progress_spinner()}
	{(!this.state.working) && this.feedback_list()}
</div>
		)
	}
}

class FeedbackUpdate extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const props = this.props;
		
		const status_options = [
			{label: 'Active', value: 'Active'},
			{label: 'Closed', value: 'Closed'},
		];

	return <div className="feedback-update">
	<div className="header">
		Update Feedback
	</div>
	<Spacer />
	<div className="p-grid">
		<div className="p-col-2">Update:</div>
		<div className="p-col-6">
		<InputTextarea className="task-edit-note p-col-10" 
			autoResize={true}
		    	value={props.par.state.details} 
			ref={inp =>  {
				if (props.autofocus)
					props.par._focusEle = inp;
			}}
			onChange={ e => props.par.setState({details: e.target.value}) }
		/>
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-2">Status:</div>
		<div className="p-col-6">
			<Dropdown value={props.par.state.fstatus}
				options={status_options}
				onChange={ e => props.par.setState({fstatus: e.value}) } 
			/>
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-2"></div>
		<div className="p-col-6">
			<Button label="Add Feedback Update" 
				icon={"pi pi-plus"}
				onClick={props.par.onUpdate}
			/>
		</div>
	</div>
	<Spacer />
	<Spacer />
	<Spacer />
	</div>
	}
}

class FeedbackView extends Component {
	constructor(props) {
		super(props);
		
		const {fid}  = this.props.match.params;

		const feedback = get_my_feedback(fid);
		
		const details = '';
		const fstatus = feedback.dt_closed ? 'Closed' : 'Active';

		this.state = {working: false,
				submitted: false,
				fid: fid,
				feedback: feedback,
				details: details,
				fstatus: fstatus,
		};
		
		window.g_viewFeedback = this;
	}

	show_status = (item) => {
		if (item.dt_closed) {
			return 'Closed';
		} else {
			return 'Active';
		}
	}
	
	onUpdate = () => {
		let {fid, details, fstatus} = this.state;
		
		const args = {
			cmd: 'update_feedback', 
			fid, details, fstatus};
		api( args, this.updated );

		this.setState({working: true});
	}

	updated = (error) => {
		this.setState({working: false, submitted: true});
	}

	progress_spinner = () => {
		return (
		<div>
			<div>
				Updating Your Feedback Item
			</div>
			<Spacer />
			<ProgressSpinner />
		</div>
		)
	}
	
	feedback_view = () => {
		const feedback = this.state.feedback;
		
		return (
		<div className="feedback-view">

<div className="p-grid first-row">
	<div className="p-col-2">Feedback ID#:</div>
	<div className="p-col-6">{ feedback.id }</div>
</div>
<div className="p-grid">
	<div className="p-col-2">Date Added:</div>
	<div className="p-col-6">{ edate3(feedback.dt_added) }</div>
</div>
<div className="p-grid">
	<div className="p-col-2">Date Updated:</div>
	<div className="p-col-6">{ edate3(feedback.dt_updated) }</div>
</div>
<div className="p-grid">
	<div className="p-col-2">Status:</div>
	<div className="p-col-6">{ this.show_status(feedback) }</div>
</div>
<div className="p-grid">
	<div className="p-col-2">Subject:</div>
	<div className="p-col-6">{ feedback.subject }</div>
</div>
<div className="p-grid">
	<div className="p-col-2">Details:</div>
	<div className="p-col-6 display-feedback-details">{ feedback.details }</div>
</div>

		</div>
		)
	}

	render() {
		return (
<div className="full-height">
	{this.state.working && this.progress_spinner()}
	{(!this.state.working) && (!this.state.submitted) && this.feedback_view()}
	{(!this.state.working) && (!this.state.submitted) && <div><Spacer /><Spacer /><Spacer /></div>}
	{(!this.state.working) && (!this.state.submitted) && <FeedbackUpdate par={this} />}
	{this.state.submitted && <div className="fill-height">
		Your feedback item has been updated.
		<Spacer />
		<Link to={go_url('feedback', 'list')} title="Return to My Feedback List">
			Return to My Feedback List
		</Link>
	</div>}

</div>
		)
	}
}

class FeedbackSubject extends Component {
	constructor(props) {
		super(props);
		const subject = props.par.state.subject;
		this.state = {subject};
	}

	onChange = e => {
		this.props.par.setState({subject: e.target.value});
	}

	render() {
		const props = this.props;
		return (
	<div className="p-grid">
	<label className="p-col-1">Subject:</label>
	<InputText className="p-col-10"
		value={props.par.state.subject} 
		placeholder=""
		onChange={this.onChange}
	/>
	</div>
		)
	}
}

class FeedbackDetails extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const props = this.props;

	return <div className="p-grid">
	<label className="p-col-1">Details:</label>
	<InputTextarea className="p-col-10 task-edit-note" 
		autoResize={true}
	    	value={props.par.state.details} 
		ref={inp =>  {
			if (props.autofocus)
				props.par._focusEle = inp;
		}}
		onChange={ e => props.par.setState({details: e.target.value}) }
	/>
	</div>
	}
}

const FeedbackCommand = props => <div className="p-grid">
	<div className="p-col-1"></div>
	<div className="p-col-10">
		<Button label={props.label} 
			icon={"pi " + (props.icon || "pi-plus")}
			onClick={props.onClick}
		/>
	</div>
  </div>


class FeedbackSubmit extends Component {
	constructor(props) {
		super(props);
		const subject = '';
		const details = '';
		const url = '';
		this.state = {working: false, submitted: false, subject,
			details, url};
		
		window.g_createFeedback = this;
	}

	componentDidMount() {
	}

	componentDidUpdate() {
	}

	componentWillUnmount() {
		this._isMounted = false;
	}
	
	onSubmit = () => {
		let {subject, details, url} = this.state;
		
		const args = {
			cmd: 'submit_feedback', 
			subject, details};
		api( args, this.submitted );
		
		this.setState({working: true});
	}

	submitted = (error) => {
		this.setState({working: false, submitted: true});
	}

	render() {

		return (
<div className="full-height">
	{this.state.working && <ProgressSpinner />}
	{this.state.submitted && <div className="fill-height">
		Thank you for reporting this issue. Your feedback item
		has been submitted and will be reviewed and updated by the
		TagInbox staff. You can see updates to your feedback item
		in the "My Feedback" link in the left-hand menu under the
		"Feedback" header.
	</div>}
	{!this.state.submitted && !this.state.working &&
		<div className="fill-height">
			<Spacer />
			<Spacer />
			<FeedbackSubject par={this} />
			<Spacer />
			<FeedbackDetails par={this} />
			<Spacer />
			<Spacer />
			<Spacer />
			<Spacer />
			<Spacer />
			<FeedbackCommand onClick={this.onSubmit} label="Submit" />
		</div>}
</div>
		)
	}
}

export {FeedbackList, FeedbackView, FeedbackSubmit};
