import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {show_new_general_activity_flag, log, show_sandbox} from './Lib';

/*
  We need LoginPage to be the default page, no matter what the
  URL is, so after login, we can redirect to that page.
  Except, when user clicks on Forgot Password or such.
  To distinguish those URLs, we'll put "public" in URL.
  LoginPage will render null if public is in the URL.
*/

const loginMenu = [
	{label: 'Login', icon: 'pi pi-fw pi-home', 
		url : '#/'},
	{label: 'Forgot Password', icon: 'fa fa-fw fa-key', 
		url : "#/public/forgotpw"},
];

let help_items = [
	{
		label: 'About',
		icon: 'fa fa-fw fa-info-circle',
		url : '#/documentation',
	},
	{
		label: 'Welcome',
		icon: 'fa fa-fw fa-envelope-o',
		url : '#/mailbox_monitoring_info',
	},
	{
		label: 'My Feedback',
		icon: 'fa fa-fw fa-files-o',
		url : '#/feedback/list',
	},
	{
		label: 'Submit Feedback',
		icon: 'fa fa-fw fa-pencil-square-o',
		url : '#/feedback/submit',
	}
];

if (show_sandbox()) {
	help_items.push({
		label: 'Sandbox',
		icon: 'fa fa-fw fa-wrench',
		url : '#/sandbox',
	});
}

const clientWinMenu = [
	// {label: 'Dashboard', 
		// icon: 'pi pi-fw pi-home', url :'#/'},
	
	{label: 'My Rooms', 
		// icon: 'pi pi-fw pi-th-large', 
		icon: 'fa fa-fw fa-folder-open', 
		url : '#/myrooms',
	},
	{label: 'Recent', icon: 'fa fa-fw fa-backward', 
		items: [
		{
			label: 'Rooms',
			// icon: 'pi pi-fw pi-th-large', 
			icon: 'fa fa-fw fa-folder-open', 
			url : '#/recent/rooms',
		},
		{
			label: 'Drafts',
			icon: 'fa fa-fw fa-pencil', 
			url : '#/recent/drafts',
		},
		],
	},
	{label: 'Tags', icon: 'pi pi-fw pi-tags', 
		url : '#/mytags',
		badge: 0,
		badge_tooltip : 'Unread Tags',
	},
	{label: 'Tasks', 
		// icon: 'fa fa-fw fa-list-alt', 
		icon: 'fa fa-fw fa-calendar-check-o', 
		url : '#/task',
	},
	{label: 'Create Room', 
		icon: 'pi pi-fw pi-plus', 
		url : '#/add/create_room',
	},
	/*
	{label: 'Bulk Create Rooms', 
		icon: 'pi pi-fw pi-plus', 
		url : '#/add/bulk_create_rooms',
	},
	{label: 'Org-Chat',
		icon: 'fa fa-fw fa-comments',
		url : "#/chat"},
	*/
	{label: 'My Mailbox',
		icon: 'fa fa-fw fa-envelope',
		url : "#/mailbox"}, // ldb postfix will replace url
	{label: 'My Org', icon: 'fa fa-fw fa-sitemap', 
		items: [
		{
			label: 'Staff List',
			icon: 'pi pi-fw pi-users', 
			url : '#/settings/staff',
		},
		{
			label: 'My Profile',
			icon: 'pi pi-fw pi-user', 
			url : '#/settings/profile',
		},
		{
			label: 'Organization',
			icon: 'fa fa-fw fa-building-o',
			url : '#/settings/org',
		},
		{
			label: 'Activity Logs',
			icon: 'fa fa-fw fa-book',
			url : '#/settings/logs',
		},
		{
			label: 'Notification Logs',
			icon: 'fa fa-fw fa-flag',
			url : '#/settings/notification_logs',
		},
		],
	},
	{label: 'Find Emails',
		icon: 'fa fa-fw fa-search',
		url : "#/find_emails"}, 
	{label: 'Help',
		icon: 'fa fa-fw fa-info', 
		items: help_items
	},
];

const url2menu = {};

function gen_url2menu(root,seq) {
	root.forEach((item, i) => {
		const nseq = [...seq, i];
		if (item.items)
			gen_url2menu(item.items, nseq);
		else
			url2menu[item.url] = nseq;
	});
}

gen_url2menu(clientWinMenu, []);

function update_menu(label, key, value) {
	clientWinMenu.forEach(item => {
		if (item.label == label)
			item[key] = value;
	});
}

function set_tag_count(value) {
	log('menu', 'set_tag_count', value);
	update_menu('Tags', 'badge', value);
}

window.g_clientWinMenu = clientWinMenu;
window.g_url2menu = url2menu;

export {loginMenu, clientWinMenu, url2menu, set_tag_count, update_menu};
