const Version = [
	{
	number: 1022,
	when: 'Thursday, January 25, 2024',
	summary: 'v1.022',
	details: [
	]
	},
	{
	number: 1021,
	when: 'Thursday, June 29, 2023',
	summary: 'v1.021',
	details: [
	]
	},
	{
	number: 500,
	when: 'Thursday, March 3, 2022',
	summary: 'Calendar support',
	details: [
		'Rudimentary calendar support',
		'Gracefully handle send mail failures, allow users to retrieve unsent email',
	]
	},
	{
	number: 400,
	when: 'Saturday, December 11, 2021',
	summary: 'OAuth Added',
	details: [
		'Added the ability to make oauth connections for email accounts',
		'Bug and stability fixes',
	]
	},

	{
	number: 302,
	when: 'Thursday, July 19, 2021',
	summary: 'Connection Fix',
	details: [
		'Improve connection loss detection and notification',
	]
	},

	{
	number: 301,
	when: 'Thursday, July 8, 2021',
	summary: 'Tweak Lists..',
	details: [
		'List Delete issue fixed',
		'Completed items are at the bottom of the list',
		'Log shows who completed the items, and unchecked completed items',
	]
	},

	{
	number: 300,
	when: 'Friday, July 2, 2021',
	summary: 'Major upgrade. Lists, Reconnect logic, Archive etc.',
	details: [
		'Base framework is updated to the latest version, so UI look and feel has changed in several places.',
		'Reconnect logic is revamped. Stale connections error out faster. Reconnect links take users back to where they were',
		'Lists feature is added to track private/shared to do lists',
		'Archive will let you create sub rooms to reduce clutter',
		'One button to download all attachments',
		'Updates to email alerts feature',
		'Miscellaneous fixes',
	]
	},

	{
	number: 238,
	when: 'Friday, June 18, 2021',
	summary: 'Reconnection logic updated',
	details: [
		'When connection is lost, we prompt user to relogin',
	]
	},

	{
	number: 237,
	when: 'Wednesday, May 12, 2021',
	summary: 'Bulk Email Re-Enabled',
	details: [
		'Shared UI tweak so toolbar stays on one line on laptops : Forward name removed, just icon, etc.',
		'Fixed errors on with rename and recategorize room',
		'Fix for tags not being marked as read when the email had already been fetched before in the session',
		'Re-enabled the bulk email sending feature',
	]
	},

	{
	number: 236,
	when: 'Monday, April 26, 2021',
	summary: 'Tag Badges',
	details: [
		'Show count of unread tags',
	]
	},

	{
	number: 235,
	when: 'Saturday, April 17, 2021',
	summary: 'Keep Emails Open in Windows',
	details: [
		'Windows to keep emails open',
	]
	},

	{
	number: 234,
	when: 'Sunday, March 7, 2021',
	summary: 'Support links in Email compose',
	details: [
		'Detect links and convert to links in email compose.',
	]
	},

	{
	number: 233,
	when: 'Sunday, March 7, 2021',
	summary: 'Email Draft Updates',
	details: [
		'Redesign of email drafts to be more efficient and load quicker.',
	]
	},

	{
	number: 232,
	when: 'Monday, March 1, 2021',
	summary: 'Attachment Changes',
	details: [
		'Show Inline Attachments as Downloadable/External for application/pdf etc.',
	]
	},

	{
	number: 231,
	when: 'Tuesday, January 12, 2021',
	summary: 'Misc changes',
	details: [
		'Bulk Email Send now has Select All and Clear All',
		'Reply to email fixes original email date',
	]
	},

	{
	number: 230,
	when: 'Saturday, January 2, 2021',
	summary: 'Enhancements to Room Creation, bulk and regular',
	details: [
		'Support ATX CSV format for bulk room creation',
		'UI Enhancements to bulk room creation',
		'UI Enhancements to room creation',
	]
	},

	{
	number: 229,
	when: 'Friday, December 25, 2020',
	summary: 'Create Room Redesign',
	details: [
		'Simplify Room Creation for Novice Users',
	]
	},

	{
	number: 228,
	when: 'Saturday, December 19, 2020',
	summary: 'Bulk Room Creation',
	details: [
		'Bulk Room Creation Framework for importing CSV',
		'Include original attachments in reply or reply-all works',
	]
	},

	{
	number: 227,
	when: 'Friday, December 11, 2020',
	summary: 'Send Email Changes',
	details: [
		'Richer multipart: mixed/related/alternative to support alternative, inline images, attachments, etc.',
		'Extend compose editor to have full menu including inline images',
		'Update Signature edit/display to include inline images and display colors/font etc.',
		'Add support for x-real-ip to carry it through reverse proxy for nginx, for whitelisting code',
		'Tags command has richer display, with tagged emails',
		'Recent command',
	]
	},

	{
	number: 226,
	when: 'Monday, December 7, 2020',
	summary: 'Send Email Changes',
	details: [
		'Send text and html as multipart alternative to not have one show up as attachment. also change charset to utf8.',
	]
	},

	{
	number: 225,
	when: 'Saturday, December 5, 2020',
	summary: 'Compose etc.',
	details: [
		'Mobile Mode errors for New Flags fixed',
		'Email Compose, To Rows, Redesign.',
		'Cycle through s,m,L modes. Detect mini laptop mode and make room for larger edit window.',
		'Show color for recipients in reply/all',
	]
	},

	{
	number: 224,
	when: 'Wednesday, December 2, 2020',
	summary: 'Mobile Mode',
	details: [
		'New Mobile Mode',
	]
	},

	{
	number: 223,
	when: 'Sunday, November 21, 2020',
	summary: 'Implicit Forward',
	details: [
		'Implicit Forward when replying to emails sent to other staff',
		'Add Contacts tab to My Mailbox, so adding recipients as clients to rooms works',
		'Add Client now lets you choose from your contacts',
		'Enable inline edting in Rooms View',
		'Search Persons db also for To dropdown',
	]
	},

	{
	number: 222,
	when: 'Thursday, November 19, 2020',
	summary: 'New flags etc.',
	details: [
		'New flags for Chat',
		'Done Checkbox in Update Task',
	]
	},

	{
	number: 221,
	when: 'Thursday, November 19, 2020',
	summary: 'New flags etc.',
	details: [
		'New flags are updated for emails and tasks',
	]
	},

	{
	number: 220,
	when: 'Monday, November 16, 2020',
	summary: 'Category fix etc.',
	details: [
		'Inline editing category updates',
	]
	},

	{
	number: 219,
	when: 'Tuesday, November 10, 2020',
	summary: 'IMAP Polling etc.',
	details: [
		'Support non-idle mail servers',
		'Bug fixes',
	]
	},

	{
	number: 218,
	when: 'Thursday, October 8, 2020',
	summary: 'My Rooms Redesigned',
	details: [
		'MyRooms split into Room List and Room Detail',
		'Notifications organized in Detail',
	]
	},

	{
	number: 217,
	when: 'Monday, September 28, 2020',
	summary: 'Inline Images',
	details: [
		'Inline Images supported: Display, Reply and Forward',
		'All images are supported in display. TBD restricted',
		'HTML is sanitized before adding to Editor for modification',
	]
	},

	{
	number: 216,
	when: 'Thursday, September 17, 2020',
	summary: 'Room Info Fields',
	details: [
		'Major New Feaure: Custom Room Info Fields',
		'Stability fixes for Memory Errors on Server',
	]
	},

	{
	number: 215,
	when: 'Sunday, September 13, 2020',
	summary: 'Notifications',
	details: [
		'Major New Feature: Room Triggers. Phrases in From or Subject can cause emails to be added to Pending. Org Admins can set, preview or reset Triggers. This affects existing emails and incoming emails.',
		'Summary tab is streamlined',
		'In Move Email, choosing room is searchable',
		'Undo Transfer, asks Are You Sure first',
		'Create Room, Add/Remove Staff is dynamic. New staff tunes into the room channel right away.',
		'Create Room feedback is improved. Creator is notified when room si ready and modal dialog is removed. Other staff are also notified.',
	]
	},

	{
	number: 214,
	when: 'Sunday, September 6, 2020',
	summary: 'Compose',
	details: [
		'Sending email is now its own tab',
		'Works for both Rooms and My Mailbox (unshared)',
		'Remembers iid and command when switching tabs and going back to compose tab',
		'Stacks forwards and replies. cancel goes back to previous',
		'Switches to replied or composed message when switching tabs',
		'Compose-Recipients allow name click-to toggle also',
		'Add Remove-All to remove all contacts',
		'Add Reset to undo all changes in compose',
		'Fix focus issue toggling between subject and body',
		'Type and filter names or new emails directly in To in Compose',
	]
	},

	{
	number: 213,
	when: 'Monday, August 31, 2020',
	summary: 'Merge Clients',
	details: [
		'Merge Clients with Alternate Emails',
		'Un-merge Clients to undo above',
		'Enhance room creation to allow merging contacts, editing name in place, sending multiple emails for same name, modify add client to also allow multiple email addresses',
		'Handle image attachment exception, when they are embedded and is_attachment is not set',
	]
	},

	{
	number: 212,
	when: 'Wednesday, August 26, 2020',
	summary: 'Org Admin Manage Commands',
	details: [
		'Separate Profile into Staff vs Org',
		'In Org Profile, if staff is org admin, show Manage links',
		'Bulk Assign Staff to Rooms',
		'Bulk Remove Staff from Rooms',
		'View Other Staffs Active Tags',
		'View Other Staffs Active Tasks',
		'Update Progress Circle. Show alert when new bg task is added. remove tooltip.',
	]
	},

	{
	number: 211,
	when: 'Saturday, August 22, 2020',
	summary: 'Misc',
	details: [
		'Fix single undo transfer',
		'Delete recipients in Compose tab itself without having to go to Recipients tab',
	]
	},

	{
	number: 210,
	when: 'Saturday, August 22, 2020',
	summary: 'SMS Replies and Client-Pending Fixes',
	details: [
		'SMS Replies from T-Mobile now get handled properly',
		'Sending emails from My Mailbox will preserve client-room mapping',
	]
	},

	{
	number: 209,
	when: 'Wednesday, August 19, 2020',
	summary: 'Express Signup, Metrics Auth',
	details: [
		'Express signup skips getting mail server details',
		'Metrics Auth',
	]
	},

	{
	number: 208,
	when: 'Tuesday, August 18, 2020',
	summary: 'Circular Progress Bar, Tagged email search etc',
	details: [
		'Circular progress bar tracks batches of background move/undo',
		'Emails can be searched by tag, untagged, tagby',
		'SMS replies from Verizon, now automatically opens attachment and displays reply',
	]
	},

	{
	number: 207, 	
	when: 'Wednesday, August 13, 2020',
	summary: 'Updates to UI features',
	details: [
		'A list of tags and tasks assigned by you can be viewed',
		'Bug fix for chat display order',
		'More user-friendly confirmation messages and errors on mailbox setup',
		'Display text file attachment with no encoding',
		'My mailbox items can be deleted with no confirmation step',
		'Added the ability to print emails and tasks',
	]
	},

	{
	number: 206, 	
	when: 'Saturday, July 25, 2020',
	summary: 'Core Email Send Fixes',
	details: [
		'Commas in recipient display names',
		'HTML message encoding switched to base64',
	]
	},
];

export {Version};
