import React, {Component, useState, useRef, useEffect} from 'react'; 
import {Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import {Checkbox} from 'primereact/checkbox';
import {Menu} from 'primereact/menu';
import {confirmDialog} from 'primereact/confirmdialog';
import {OrderList} from 'primereact/orderlist';
import DnDList from 'react-dnd-list';
import {Toolbar} from 'primereact/toolbar';
import {Panel} from 'primereact/panel';
import {ScrollPanel} from 'primereact/scrollpanel';
import {InputText} from 'primereact/inputtext';
import {Inplace, InplaceDisplay, InplaceContent} from 'primereact/inplace';
import {TabView, TabPanel} from 'primereact/tabview';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, TaskDueDate,
	TaskCategory, TaskCommand, TaskSelectStaff,} from './EditTask';
import {show_name} from './TaskUtil';
import {ModalCommand, Spacer, Focusable, FakeLink, lazyFocus, 
	HelpInfo} from './Utils';

import {ldb, log, gen_email_summary, task_category_default, regcomp,
	go_url, url2room, url2iid, go, em, fulldatetime, gen_xid,
	get_first_name, get_staff, get_room, set_order, resort,
	priorities, get_tag, pri_v2n, edate, env_tags, rel_url,
	task_due_date_delta_default, growl, api, pri_n2v, edate2,
	edate3, get_tag_by_me, is_tag_read, is_my_tag_read, init_list,
	map_list, foreach_list, copy_to_clipboard,
	show_is_new, pid2person, get_mailbox_room} from './Lib';



class FindEmails extends Component {
	constructor(props) {
		super(props);
		
		let search_method = 'recent';
		let search_value = '10';
		
		let result_method = '';
		let result_value = '';
		let result_envs = [];

		let submitted = false;
		
		if (ldb.data.find_emails_results) {
			search_method = ldb.data.find_emails_results.search_method;
			search_value = ldb.data.find_emails_results.search_value;
			
			result_method = ldb.data.find_emails_results.search_method;
			result_value = ldb.data.find_emails_results.search_value;
			result_envs = ldb.data.find_emails_results.envs;

			submitted = true;
		}
		
		this.state = {working: false, submitted, search_method, search_value, result_method, result_value, result_envs};
		
		window.g_findEmails = this;
	}
	
	componentDidMount() {
		if (!this.state.submitted) {
			this.onSubmit();
		}
	}
	
	componentDidUpdate() {
	}
	
	componentWillUnmount() {
		this._isMounted = false;
	}
	
	onSubmit = () => {
		let {search_method, search_value} = this.state;
		
		if (!(search_value.trim())) {
			growl('Error', 'Enter a search value', 'error');
			return;
		}

		if ((search_method == 'recent') && (!/^\d+$/.test(search_value))) {
			growl('Error', 'Enter a valid number of recent emails', 'error');
			return;
		}
		
		const args = {
			cmd: 'find_emails', 
			search_method,
			search_value
		};
		
		api(args, this.submitted);
		
		this.setState({working: true});
	}

	submitted = (error, db) => {
		if (error) {
			growl('Error', error, 'error');
			return;
		}
		
		let results = db.search_results;
		
		ldb.data.find_emails_results = results;

		this.setState({working: false, submitted: true, result_method: results.search_method, result_value: results.search_value, result_envs: results.envs});
	}

	keyDown = e => {
		if (e.key === 'Enter') {
			this.onSubmit();
		}
	}

	dropdownChange = e => {
		this.setState({search_method: e.value});
		if (e.value == 'recent') {
			this.setState({search_value: '10'});
		} else {
			this.setState({search_value: ''});			
		}
	}
	
	render() {
		const props = this.props;
		
		const search_method_options = [
			{label: 'Recent', value: 'recent'},
			{label: 'Subject', value: 'subject'},
			{label: 'Sender/Recipients', value: 'sender_recipients'},
			{label: 'Message ID', value: 'message_id'}
		];
		
		return (
	<div className="card">
	<div>
	
	<div>
	<div className="find-emails-input">
		<Dropdown
			value={this.state.search_method}
			options={search_method_options}
			onChange={this.dropdownChange}
			className="find-emails-input-dropdown"
		/>
	</div>
	{em(2)}
	<div className="find-emails-input">
		<InputText
			value={this.state.search_value} 
			placeholder=""
			onChange={ e => this.setState({search_value: e.target.value}) }
			onKeyDown={this.keyDown}
			className="find-emails-input-text"
		/>
	</div>
	{em(2)}
	<div className="find-emails-input">
		<Button 
			label="Find"
			onClick={this.onSubmit}
		/>
	</div>
	</div>
	
	<hr/>
	
	<div className="find-emails-results">
	
	{this.state.working && <ProgressSpinner />}
	
	{(this.state.submitted) && (!this.state.working) && <div>

	{(this.state.result_envs.length == 0) && <div>
		No matching emails found
	</div>}
	
	{(this.state.result_envs.length > 0) && <div className="p-grid find-emails-header-div">
		<div className="p-col-2 find-emails-header-col find-emails-header-col-left">From</div>
		<div className="p-col-3 find-emails-header-col">Subject</div>
		<div className="p-col-2 find-emails-header-col">Date</div>
		<div className="p-col-2 find-emails-header-col">Tab</div>
		<div className="p-col-3 find-emails-header-col">Rooms</div>
		{this.state.result_envs.map( (u, i) => {
			let fname = '';
			let rooms = [];
			let tab = '';
			let subject = '(No Subject)';

			if (u.rid) {
				let pinfo = JSON.parse(u.pinfo_json);
				if (pinfo.from[0].name) {
					fname = pinfo.from[0].name;
				} else {
					fname = pinfo.from[0].email;
				}
				
				rooms.push(u.rid);

				tab = 'Shared';
			} else {
				if (u.from_pids) {
					let fper = pid2person(u.from_pids);
					if (fper) {
						if (fper.name) {
							fname = fper.name;
						} else {
							fname = fper.email;
						}
					}
				}

				if (u.home_room_data) {
					rooms = u.home_room_data;
					
					if (rooms.length == 0) {
						let r = get_mailbox_room();
						rooms.push(r.id);
					} else {
						
					}
				}
				
				tab = 'Unshared';
			}
			
			if (u.subject) {
				subject = u.subject;
			}
			
			let klass = i % 2 ? 'oddrow' : 'evenrow';
			
			return (<React.Fragment key={"e_" + i}>
				<div className={"p-col-2 find-emails-env-col-ellipsis find-emails-header-col-left " + klass}>{fname}</div>
				<div className={"p-col-3 find-emails-env-col-ellipsis " + klass}>{subject}</div>
				<div className={"p-col-2 " + klass}>{edate3(u.dt_sent)}</div>
				<div className={"p-col-2 " + klass}>{tab}</div>
				{(rooms.length > 1) && <div className={"p-col-3 find-emails-env-col-ellipsis " + klass}>
						<ul className="find-emails-env-room-list">
						{rooms.map( (h, j) => {
						let r = get_room(h);
						if (!r) {
							return null;
						}
						return (<li key={"r_" + j}><Link to={go_url('room', h, tab.toLowerCase(), u.id)}>{r.name}</Link></li>)
						})}
						</ul>
				</div>}
				{(rooms.length == 1) && <div className={"p-col-3 find-emails-env-col-ellipsis " + klass}>{rooms.map( (h, j) => {
						let r = get_room(h);
						if (!r) {
							return null;
						}
						return (<Link key={"r_" + j} to={go_url('room', h, tab.toLowerCase(), u.id)}>{r.name}</Link>)
						})}
				</div>}
				</React.Fragment>);
		})}
	</div>}
	
	</div>}
	
	</div>
	
	</div>
	</div>
	)
	}
}

export {FindEmails};
